import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import * as ReactBootstrap from "react-bootstrap";
import React from "react";

const resources = {
  en: {
    translation: {
      "Feedback": "Feedback",
      "English": "English",
      "German": "German",
      "French": "French",
      "Italian": "Italian",
      "Greek": "Greek",
      "Dutch": "Dutch",
      "Suizze-Italian": "Suizze-Italian",
      "Suizze-French": "Suizze-French",
      "Suizze-German": "Suizze-German",
      "Luxembourg-German": "Luxembourg-German",
      "Luxembourg-French": "Luxembourg-French",
      "language": "Language",
      "Name": "Name",
      "Email": "Email",
      "Subject": "Subject",
      "Message": "Message",
      "Message_Record": "Do you want to record a sign message",
      "Message_language": "Select a sign language for recording",
      "Message_no_record": "No recording",
      "video-record": "Video record",
      "video-one": "Click on the record icon to activate recording",
      "video-two": "Click on the white button left from the timer for start and stop",
      "agreement": "I agree to transmit the Video to EASIER Team",
      "Submit": "Submit",
      "email-invalid": "Invalid email",
      "email-required": "E-Mail is required",
      "subject-required": "Subject is required",
      "message-required": "Message is required",
      "form-submitted": "Form submitted",
      "successful": "Successful",
    }
  },
  de: {
    translation: {
      "Feedback": "Feedback",
      "English": "Englisch",
      "German": "Deutsch",
      "French": "Französisch",
      "Italian": "Italienisch",
      "Greek": "Griechisch",
      "Dutch": "Niederländisch",
      "Suizze-Italian": "Schweizer-Italienisch",
      "Suizze-French": "Schweizer-Französisch",
      "Suizze-German": "Schweizer-Deutsch",
      "Luxembourg-German": "Luxemburg-Deutsch",
      "Luxembourg-French": "Luxemburg-Französisch",
      "language": "Sprache",
      "Name": "Name",
      "Email": "E-Mail",
      "Subject": "Titel",
      "Message": "Nachricht",
      "Message_Record": "Möchten Sie eine Zeichennachricht aufnehmen",
      "Message_language": "Wählen Sie eine Gebärdensprache für die Aufnahme",
      "Message_no_record": "Keine Aufnahme",
      "video-record": "Video aufnehmen",
      "video-one": "Klicken Sie auf das Aufnahmesymbol, um die Aufnahme zu aktivieren.",
      "video-two": "Klicken Sie auf die weiße Schaltfläche links neben dem Timer für Start und Stopp",
      "agreement": "Ich bin damit einverstanden, das Video an das EASIER-Team zu übermitteln",
      "Submit": "Absenden",
      "email-invalid": "Ungültige E-Mail",
      "email-required": "E-Mail wird benötigt",
      "subject-required": "Titel wird benötigt",
      "message-required": "Nachricht wird benötigt",
      "form-submitted": "Formular eingereicht",
      "successful": "Erfolgreich",
    }
  },
  ch: {
    translation: {
      "Feedback": "Feedback",
      "English": "Englisch",
      "German": "Deutsch",
      "French": "Französisch",
      "Italian": "Italienisch",
      "Greek": "Griechisch",
      "Dutch": "Niederländisch",
      "Suizze-Italian": "Schweizer-Italienisch",
      "Suizze-French": "Schweizer-Französisch",
      "Suizze-German": "Schweizer-Deutsch",
      "Luxembourg-German": "Luxemburg-Deutsch",
      "Luxembourg-French": "Luxemburg-Französisch",
      "language": "Sprache",
      "Name": "Name",
      "Email": "E-Mail",
      "Subject": "Titel",
      "Message": "Nachricht",
      "Message_Record": "Möchten Sie eine Zeichennachricht aufnehmen",
      "Message_language": "Wählen Sie eine Gebärdensprache für die Aufnahme",
      "Message_no_record": "Keine Aufnahme",
      "video-record": "Video aufnehmen",
      "video-one": "Klicken Sie auf das Aufnahmesymbol, um die Aufnahme zu aktivieren.",
      "video-two": "Klicken Sie auf die weiße Schaltfläche links neben dem Timer für Start und Stopp",
      "agreement": "Ich bin damit einverstanden, das Video an das EASIER-Team zu übermitteln",
      "Submit": "Absenden",
      "email-invalid": "Ungültige E-Mail",
      "email-required": "E-Mail wird benötigt",
      "subject-required": "Titel wird benötigt",
      "message-required": "Nachricht wird benötigt",
      "form-submitted": "Formular eingereicht",
      "successful": "Erfolgreich",
    }
  },
  it: {
    translation: {
      "Feedback": "Feedback",
      "English": "Inglese",
      "German": "Tedesco",
      "French": "Francese",
      "Italian": "Italiano",
      "Greek": "Greco",
      "Dutch": "Olandese",
      "Suizze-Italian": "Svizzero-italiano",
      "Suizze-French": "Svizzero-francese",
      "Suizze-German": "Svizzero-tedesco",
      "Luxembourg-German": "Lussemburgo-tedesco",
      "Luxembourg-French": "Lussemburgo-francese",
      "language": "Lingua",
      "Name": "Nome",
      "Email": "Email",
      "Subject": "Titolo",
      "Message": "Messaggio",
      "Message_Record": "Vuoi registrare un messaggio di cartello",
      "Message_language": "Selezionare una lingua dei segni per la registrazione",
      "Message_no_record": "Nessuna registrazione",
      "video-record": "Registrazione video",
      "video-one": "Fare clic sull'icona di registrazione per attivare la registrazione",
      "video-two": "Fare clic sul pulsante bianco a sinistra del timer per avviare e interrompere",
      "agreement": "Accetto di trasmettere il video al team EASIER",
      "Submit": "Invia",
      "email-invalid": "Email non valida",
      "email-required": "L'e-mail è richiesta",
      "subject-required": "L'oggetto è richiesto",
      "message-required": "Il messaggio è richiesto",
      "form-submitted": "Modulo inviato",
      "successful": "Successo",
    }
  },
  fr: {
    translation: {
      "Feedback": "Commentaires",
      "English": "Anglais",
      "German": "Allemand",
      "French": "Français",
      "Italian": "Italienne",
      "Greek": "Grec",
      "Dutch": "Néerlandais",
      "Suizze-Italian": "Suisse-Italienne",
      "Suizze-French": "Suisse-Français",
      "Suizze-German": "Suisse-Allemand",
      "Luxembourg-German": "Luxembourg-Allemand",
      "Luxembourg-French": "Luxembourg-Français",
      "language": "Langue",
      "Name": "Nom",
      "Email": "Email",
      "Subject": "Titre",
      "Message": "Message",
      "Message_Record": "Voulez-vous enregistrer un message signé",
      "Message_language": "Sélectionnez une langue des signes pour l'enregistrement",
      "Message_no_record": "Pas d'enregistrement",
      "video-record": "Enregistrement vidéo",
      "video-one": "Cliquez sur l'icône d'enregistrement pour activer l'enregistrement",
      "video-two": "Cliquez sur le bouton blanc à gauche du minuteur pour le démarrage et l'arrêt",
      "agreement": "J'accepte de transmettre la vidéo à l'équipe EASIER",
      "Submit": "Soumettre",
      "email-invalid": "Courriel non valide",
      "email-required": "L'e-mail est requis",
      "subject-required": "Titre est requis",
      "message-required": "Message est requis",
      "form-submitted": "Formulaire soumis",
      "successful": "Réussi",
    }
  },
  gr: {
    translation: {
      "Feedback": "Ανατροφοδότηση",
      "English": "Αγγλικά",
      "German": "Γερμανικά",
      "French": "Γαλλικά",
      "Italian": "Ιταλικά",
      "Greek": "Ελληνική",
      "Dutch": "Ολλανδικά",
      "Suizze-Italian": "Ελβετικό-ιταλικό",
      "Suizze-French": "Ελβετικό-Γαλλικά",
      "Suizze-German": "Ελβετικό-Γερμανικά",
      "Luxembourg-German": "Λουξεμβούργο-Γερμανικά",
      "Luxembourg-French": "Λουξεμβούργο-Γαλλικά",
      "language": "Γλώσσα",
      "Name": "Όνομα",
      "Email": "Email",
      "Subject": "Τίτλος",
      "Message": "Μήνυμα",
      "Message_Record": "Θέλετε να ηχογραφήσετε ένα μήνυμα",
      "Message_language": "Επιλέξτε μια νοηματική γλώσσα για εγγραφή",
      "Message_no_record": "Καμία εγγραφή",
      "video-record": "Εγγραφή βίντεο",
      "video-one": "Κάντε κλικ στο εικονίδιο εγγραφής για να ενεργοποιήσετε την εγγραφή",
      "video-two": "Κάντε κλικ στο λευκό κουμπί αριστερά από το χρονόμετρο για έναρξη και διακοπή",
      "agreement": "Συμφωνώ να διαβιβάσω το βίντεο στην ομάδα EASIER",
      "Submit": "Υποβολή",
      "email-invalid": "Μη έγκυρο email",
      "email-required": "Απαιτείται ηλεκτρονικό ταχυδρομείο",
      "subject-required": "Απαιτείται το θέμα",
      "message-required": "Απαιτείται μήνυμα",
      "form-submitted": "Έντυπο που υποβλήθηκε",
      "successful": "Επιτυχής",
    }
  },
  nl: {
    translation: {
      "Feedback": "Terugkoppeling",
      "English": "Engels",
      "German": "Duits",
      "French": "Frans",
      "Italian": "Italiaans",
      "Greek": "Grieks",
      "Dutch": "Nederlands",
      "Suizze-Italian": "Zwitsers-Italiaans",
      "Suizze-French": "Zwitsers-Frans",
      "Suizze-German": "Zwitsers-Duits",
      "Luxembourg-German": "Luxemburg-Duits",
      "Luxembourg-French": "Luxemburg-Frans",
      "language": "Taal",
      "Name": "Naam",
      "Email": "E-mail",
      "Subject": "Titel",
      "Message": "Bericht",
      "Message_Record": "Wilt u een gebarenbericht opnemen",
      "Message_language": "Selecteer een gebarentaal voor opname",
      "Message_no_record": "Geen opname",
      "video-record": "Video opnemen",
      "video-one": "Klik op het opname-icoon om de opname te activeren",
      "video-two": "Klik op de witte knop links van de timer voor start en stop",
      "agreement": "Ik ga akkoord met het doorsturen van de video naar het EASIER-team",
      "Submit": "Verzenden",
      "email-invalid": "Ongeldige e-mail",
      "email-required": "E-Mail is vereist",
      "subject-required": "Titel is vereist",
      "message-required": "Bericht is vereist",
      "form-submitted": "Formulier ingediend",
      "successful": "Succesvol",
    }
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
