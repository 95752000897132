/* Packages */
import React, {useEffect} from "react";
import './i18n';
import * as ReactBootstrap from "react-bootstrap";
import {useTranslation} from "react-i18next";

/* Components */
import Header from "./components/header";
import Footer from "./components/footer";
import FeedbackForm from "./components/feedbackForm";

/* Icons */
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/js/all.js';

/* node_modules / src*/
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import './App.css';

function App() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const language = urlParams.get('language') ? urlParams.get('language') : "";

    if(language.toUpperCase() === "DE" || language.toLowerCase() === "dgs"){
      i18n.changeLanguage("de")
    }
    else if(language.toUpperCase() === "CH" || language.toLowerCase() === "dsgs"){
      i18n.changeLanguage("ch")
    }
    else if(language.toUpperCase() === "EN" || language.toLowerCase() === "bsl"){
      i18n.changeLanguage("en")
    }
    else if(language.toUpperCase() === "FR" || language.toLowerCase() === "lsf"){
      i18n.changeLanguage("fr");
    }
    else if(language.toUpperCase() === "GR" || language.toLowerCase() === "gsl"){
      i18n.changeLanguage("gr");
    }
    else if(language.toUpperCase() === "IT" || language.toLowerCase() === "lis"){
      i18n.changeLanguage("it");
    }
    else if(language.toUpperCase() === "NL" || language.toLowerCase() === "ngt" || language.toLowerCase() === "sln"){
      i18n.changeLanguage("nl");
    }
    else if(language === "" || language === null || language === undefined){
      i18n.changeLanguage("en");
    }
    else {
      i18n.changeLanguage("en");
    }
  }, []);

  return (
    <div className="App">
      <Header />
      <FeedbackForm />
      <Footer />
    </div>
  );
}

export default App;
